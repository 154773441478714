<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <router-view :key="key" ref="routerview"/>
    </transition>
  </section>
</template>

<script>
export default {
  computed: {
    key() {
      return this.$route.path;
    }
  }
};
</script>

<style lang="less" scoped>
  .app-main {
    //max-width: @main-width;
    margin: 0 auto;
    padding-top: 60px;
    position: relative;
    min-height: 800px;
    &::before {
      content: " ";
      position: absolute;
      top: 0;
      background: rgb(15, 13, 26);
      left: 0;
      right: 0;
      width: 100%;
      height: 60px;
    }
  }
</style>./appMain.vue
